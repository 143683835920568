
import { defineComponent } from 'vue';
import Breadcrumb from '@/views/new-design/layout/Breadcrumb.vue';
import TenantReview from '@/views/new-design/pages/Tenants/ViewReference/ReferenceReport.vue';

export default defineComponent({
  components: { Breadcrumb, TenantReview },
  data() {
    return {
      breadcrumbs: [
        {
          to: '/references',
          text: 'Tenant References',
          current: false,
        },
        {
          to: `/references/${this.$route.params.reference_id}`,
          text: 'Tenant Report',
          current: true,
        },
      ],
    };
  },
});


import { defineComponent, onMounted } from 'vue';
import History from '@/views/new-design/pages/Tenants/ViewReference/History.vue';
import Report from '@/views/new-design/pages/Tenants/ViewReference/Report.vue';
import AgentDetails from '@/views/new-design/pages/Tenants/ViewReference/AgentDetails.vue';
import { setCurrentPageTitle } from '@/core/helpers/breadcrumb';
import { mapActions, mapGetters } from 'vuex';
import { Getters, Actions, Mutations } from '@/store/enums/TenantReviewEnums';
import { Actions as StoreActions } from '@/store/enums/StoreEnums';
import { TenantReview } from '@/models/TenantReviewModel';
import { Tenant } from '@/models/TenantModel';
import { numberFormat } from '@/utils/text';
import store from '@/store';
import {
  Actions as TActions,
  Getters as TGetter,
} from '@/store/enums/TenantEnums';
import GoogleMap from '@/views/new-design/components/GoogleMap.vue';
import {
  Actions as BusinessActions,
  Getters as BusinessGetters,
  Mutations as BusinessMutations,
} from '@/store/enums/BusinessEnums';
import image from '@/utils/image';
import text from '@/utils/text';
import BusinessLogo from '@/components/logo/BusinessLogo.vue';
import {
  Actions as UserActions,
  Mutations as UserMutations,
} from '@/store/enums/UserEnums';
import NoReview from '@/views/new-design/pages/Tenants/ViewReference/NoReview.vue';
import {
  Actions as ReferenceActions,
  Getters as ReferenceGetters,
  Mutations as ReferenceMutations,
} from '@/store/enums/ReferenceEnums';

const initialValues = {
  id: '',
  tenant_id: '',
  tenant: {} as Tenant,
  reference_no: '',
} as {} as unknown as TenantReview;

export default defineComponent({
  components: {
    //   History,
    Report,
    AgentDetails,
    GoogleMap,
    BusinessLogo,
    //   NoReview
  },
  data: () => ({
    currentTenantReview: initialValues,
    currentRefNo: '' as string,
    selectedRefNo: '' as string,
    routeRefNo: '' as string | string[],
    tenantId: '' as string | string[],
    loading: false,
    reviewDetailLoading: false,
    averageRating: '0',
    tenant: {} as unknown as Tenant,
    tab: 'report',
    logoData: null,
  }),
  async mounted() {
    // show page loading
    setTimeout(() => {
      store.dispatch(StoreActions.REMOVE_BODY_CLASSNAME, 'page-loading');
    }, 500);

    this.loading = true;
    this.reviewDetailLoading = true;
    setCurrentPageTitle('Get Tenant Reference');

    this.setRouteRefNo();

    this.loadTenantReviews();

    await this.loadTenantReviewByRefNo();

    this.computeAverageRating();

    this.fetchCurrentReviewAvatar();

    (this.$refs.googleMap as any).initializeMap();
  },
  computed: {
    ...mapGetters({
      tenantReview: Getters.GET_TENANT_REVIEW,
      tenantReviews: TGetter.GET_TENANT_REFERENCES,
      getTenant: TGetter.GET_TENANT,
      getBusinessLogo: BusinessGetters.GET_AGENT_BUSINESS_LOGO,
      references: ReferenceGetters.GET_REFERENCES_LISTS,
    }),
    tenantRecord() {
      return this.tenant;
    },
    currentAddress() {
      return `${(this.tenantReview as any)?.address_line}, ${
        (this.tenantReview as any)?.city
      }, ${(this.tenantReview as any)?.post_code}, ${
        (this.tenantReview as any)?.state
      }`;
    },
    headerColor() {
      if (!(this.tenantReview as any)?.user?.business_color) {
        return '#4070ea';
      }

      return (this.tenantReview as any)?.user?.business_color ?? '#4070ea';
    },
    hasLogo() {
      return (
        (this.tenantReview as any)?.user?.business_logo &&
        (this.tenantReview as any)?.user?.business_logo.trim() != ''
      );
    },
    displayLogo() {
      return (this.currentTenantReview as any)?.user?.business_logo_src;
    },
    fetchCurrentReviewAvatar() {
      return this.tenant?.user?.avatar_src;
    },
    fetchAgentAvatar() {
      return (this.currentTenantReview as any)?.user?.avatar_src;
    },
  },
  watch: {
    tenantReview: {
      handler(value) {
        store.commit(Mutations.SET_AGENT_AVATAR, '');

        if (value) {
          this.displayLogo();
          this.fetchAgentAvatar();

          this.$nextTick(() => {
            (this.$refs.googleMap as any).searchMap();
          });
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      fetchTenantReviews: ReferenceActions.FETCH_REFERENCES,
      fetchTenantReview: Actions.FETCH_TANANT_REVIEW_BY_REFERENCE_NO,
      fetchTenant: TActions.FETCH_TENANT,
      fetchBusinessLogo: BusinessActions.FETCH_AGENT_BUSINESS_LOGO,
    }),
    setRouteRefNo() {
      this.currentRefNo = (
        typeof this.$route.params.reference_id !== 'undefined'
          ? this.$route.params.reference_id
          : ''
      ) as string;
    },
    async loadTenantReviewByRefNo() {
      this.loading = true;
      this.reviewDetailLoading = true;

      await this.fetchTenantReview(this.$route.params.reference_id);
    },
    loadTenantReviews() {
      this.fetchTenantReviews();
    },
    handleSelectRentalHistory(values) {
      this.$router.push({
        name: 'view-tenant-reference',
        params: { reference_id: values },
      });
    },
    computeAverageRating() {
      this.averageRating = numberFormat(
        this.tenantReview.tenant.average_rating
      );
    },
  },
  setup() {
    // show page loading
    store.dispatch(StoreActions.ADD_BODY_CLASSNAME, 'page-loading');
    onMounted(() => {
      const key = process.env.VUE_APP_GOOGLEMAPS_KEY;
      const googleMapScript = document.createElement('SCRIPT');
      googleMapScript.setAttribute(
        'src',
        `https://maps.googleapis.com/maps/api/js?key=AIzaSyAGX7VifDi6W0AlZhM4Wkv31D2cm08s7wA&callback=initMap&libraries=places`
      );
      googleMapScript.setAttribute('defer', '');
      googleMapScript.setAttribute('async', '');
      document.head.appendChild(googleMapScript);
    });
  },
});
